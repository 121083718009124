import React from 'react'
import Layout from '../components/Layout'
import '../components/styles/about.css'
import {IoMdCall} from 'react-icons/io'
import {IoMdMail} from 'react-icons/io'
import { FaFax } from 'react-icons/fa'

function about() {
    return (
        <Layout>
            <div className="waveWrapper waveAnimation">
                <div className='text-wrapper'>
                <h1>Η Ιστοσελιδα είναι υπο κατασκευή!</h1>
                <h3><span className='icon'><IoMdCall/></span>   <span>2310 318-652</span></h3> 
                <h3><span className='icon'><FaFax /></span> Fax  <span>2316 020-540</span></h3>

                <h3><span className='icon'><IoMdMail/></span>   <span>info@olkis.gr</span></h3> 
                </div>
                <div className="waveWrapperInner bgTop">
                    <div className="wave waveTop"></div>
                </div>
                <div className="waveWrapperInner bgMiddle">
                    <div className="wave waveMiddle"></div>
                </div>
                <div className="waveWrapperInner bgBottom">
                    <div className="wave waveBottom"></div>
                </div>
            </div>
        </Layout>
    )
}

export default about
